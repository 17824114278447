import { parseWithZod } from "@conform-to/zod";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export function getInitials(name: string) {
  return name
    .split(" ")
    .filter(Boolean)
    .map((n) => n[0].toUpperCase())
    .join("");
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function parseForm<T extends z.ZodTypeAny>(args: { request: Request; schema: T }) {
  const formData = await args.request.formData();
  const submission = parseWithZod<T>(formData, { schema: args.schema });
  return submission;
}
